import React from "react"
import cuid from "cuid"
import { graphql, Link as BrowserLink } from "gatsby"
import PropTypes from "prop-types"
import {
  Container,
  Grid,
  Box,
  Typography,
  useMediaQuery,
  Link,
  Button,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { GatsbyImage } from "gatsby-plugin-image"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import MailIcon from "@mui/icons-material/Mail"
import WebIcon from "@mui/icons-material/Web"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import InstagramIcon from "@mui/icons-material/Instagram"
import FacebookIcon from "@mui/icons-material/Facebook"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"

import { LayoutChapters } from "../components/Layout"
import { PNScrollAnimation } from "../components/PNScrollAnimation"
import { Segment, Section } from "../components/Layout"
import { SegmentTitle } from "../components/SegmentTitle"
import { SectionTitle } from "../components/SectionTitle"


const ChapterTemplate = ({pageContext}) => {
    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"))

    const chapterdata = pageContext.chapterdata
    return (
    <LayoutChapters>
      <Segment>
        <Container disableGutters>
          <Box mt="70px"></Box>
          <Grid container>
            <Grid item xs={12}>
              <Button
                component={BrowserLink}
                to="/#chapters"
                startIcon={<ArrowBackIcon />}
              >
                Previous Page
              </Button>
            </Grid>
          </Grid>
          <Section>
            <PNScrollAnimation animateIn="fadeIn">
              <Grid container>
                <Grid item xs={12} md={5}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      pr: isSmall ? 0 : 4,
                    }}
                    mb={isSmall ? 4 : 0}
                  >
                    <Box
                      component="img"
                      src={chapterdata.image && chapterdata.image.localFile.publicURL}
                      alt={chapterdata.title}
                      placeholder="blurred"
                      width={490}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Box>
                    <SectionTitle align={isSmall ? "center" : "initial"}>
                      {chapterdata.title}
                    </SectionTitle>
                    <SectionTitle
                      align={isSmall ? "center" : "initial"}
                      subtitle
                      gutterBottom
                    >
                      {chapterdata.copy}
                    </SectionTitle>

                    <Box mb={2}>
                      <Typography
                        align={isSmall ? "center" : "initial"}
                        paragraph
                      >
                        {chapterdata.intro}
                      </Typography>
                    </Box>
                    <Button href={`mailto:${chapterdata.email}?subject=${chapterdata.subject}`}>
                      Request To Attend
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </PNScrollAnimation>
          </Section>
        </Container>
        <Section>
          <TableContainer component={Paper}>
            <Table sx={{ width: "100%" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Business Name</TableCell>
                  <TableCell align="right">Business Category</TableCell>
                  <TableCell align="right">City</TableCell>
                  <TableCell align="right">State</TableCell>
                  <TableCell align="right">Phone Number</TableCell>
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right">Website</TableCell>
                  <TableCell align="right">Social</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {chapterdata.members &&
                    <>
                    {chapterdata.members.map(
                        ({
                            businessCategory,
                            businessName,
                            city,
                            email,
                            facebook,
                            instagram,
                            linkedin,
                            name,
                            phone,
                            state,
                            website
                        }) => (
                            <TableRow
                            key={cuid()}
                            sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            >
                            <TableCell component="th" scope="row">
                                <AccountCircleIcon />
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {name}
                            </TableCell>
                            <TableCell align="right">{businessName}</TableCell>
                            <TableCell align="right">{businessCategory}</TableCell>
                            <TableCell align="right">{city}</TableCell>
                            <TableCell align="right">{state}</TableCell>
                            <TableCell align="right">{phone}</TableCell>
                            <TableCell align="right">
                                {email && (
                                <Link href={`mailto:${email}`}>
                                    <MailIcon />
                                </Link>
                                )}
                            </TableCell>
                            <TableCell align="right">
                                {website && (
                                <Link
                                    href={`${website}`}
                                    target="_blank"
                                    rel="noopener"
                                >
                                    <WebIcon />
                                </Link>
                                )}
                            </TableCell>
                            <TableCell align="right">
                                <Box display="flex-end" justifyContent="">
                                {linkedin && (
                                    <Link
                                    href={linkedin}
                                    target="_blank"
                                    rel="noopener"
                                    >
                                    <LinkedInIcon />
                                    </Link>
                                )}
                                {instagram && (
                                    <Link
                                    href={instagram}
                                    target="_blank"
                                    rel="noopener"
                                    >
                                    <InstagramIcon />
                                    </Link>
                                )}
                                {facebook && (
                                    <Link
                                    href={facebook}
                                    target="_blank"
                                    rel="noopener"
                                    >
                                    <FacebookIcon />
                                    </Link>
                                )}
                                </Box>
                            </TableCell>
                            </TableRow>
                        )
                    )}
                    </>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Section>
      </Segment>
    </LayoutChapters>
    )
}

export default ChapterTemplate
